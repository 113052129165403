import { Divider, Typography } from "@material-ui/core";
import React from "react";

interface ITextDividerProps {
  children: React.ReactNode;
}

export default function TextDivider({ children }: ITextDividerProps) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0' }}>
      <Divider style={{ flexGrow: 1 }} />
      <Typography style={{ margin: '0 10px' }}>{children}</Typography>
      <Divider style={{ flexGrow: 1 }} />
    </div>
  );
}
