import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const useQuery = () => {
  const { search, pathname } = useLocation();
  return useMemo(() => {
    return {
      search: new URLSearchParams(search),
      pathname,
    };
  }, [search, pathname]);
};
