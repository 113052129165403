import { Currency } from "./context/authContext/reducer";

export const currencies: Currency[] = [
  { value: "GBP", label: "British Pound", symbol: "£" },
  { value: "USD", label: "United States Dollar", symbol: "$" },
  { value: "JPY", label: "Japanese Yen", symbol: "¥" },
  { value: "EUR", label: "Euro", symbol: "€" },
  { value: "CHF", label: "Swiss Franc", symbol: "₣" },
  { value: "SGD", label: "Singapore Dollar", symbol: "$" },
  { value: "HKD", label: "Hong Kong Dollar", symbol: "$" },
];

export const livexIndexOptions = [
  {
    name: "Liv-ex Fine Wine 50",
    symbol: "LX50",
    description: "The Liv-ex Fine Wine 50 Index tracks the daily price movements of the Bordeaux First Growths. It includes the ten most recent vintages of Lafite Rothschild, Margaux, Mouton Rothschild and Haut Brion (2010-2019) and the 2005-2014 vintages of Latour.",
    type: "major",
    subIndexOf: null,
    bloombergName: "LIVXFW50",
    thomsonReutersName: "LIVFW50",
    lastRebased: null
  },
  {
    name: "Liv-ex Fine Wine 100",
    symbol: "LX100",
    description: "The Liv-ex Fine Wine 100 Index is the industry leading benchmark for monitoring fine wine prices. It represents the price movement of 100 of the most sought-after fine wines on the secondary market.",
    type: "major",
    subIndexOf: null,
    bloombergName: "LIVX100",
    thomsonReutersName: "LIVF100",
    lastRebased: null
  },
  {
    name: "Liv-ex Bordeaux 500",
    symbol: "BDX500",
    description: "The Liv-ex Bordeaux 500 is the most comprehensive index for Bordeaux wines. It is a sub-index of the Liv-ex Fine Wine 1000 and represents the price movement of 500 leading wines from the region. The index comprises six sub-indices: the Fine Wine 50, the Right Bank 50, the Second Wine 50, the Sauternes 50, the Right Bank 100 and the Left Bank 200.",
    type: "regional",
    subIndexOf: "Liv-ex Fine Wine 1000",
    bloombergName: null,
    thomsonReutersName: null,
    lastRebased: null
  },
  {
    name: "Liv-ex Fine Wine 1000",
    symbol: "LX1000",
    description: "The Liv-ex Fine Wine 1000 tracks 1,000 wines from across the world and is our broadest measure of the market.  It comprises seven sub-indices which represent the most traded wines from regions around the world: the Bordeaux 500, the Bordeaux Legends 40, the Burgundy 150, the Champagne 50, the Rhone 100, the Italy 100 and the Rest of the World 60.",
    type: "major",
    subIndexOf: null,
    bloombergName: null,
    thomsonReutersName: null,
    lastRebased: null
  },
  {
    name: "Liv-ex Fine Wine Investables",
    symbol: "LVXINV",
    description: "The Liv-ex Fine Wine Investables tracks the price performance of 195 exceptional Bordeaux wines. In order to qualify for inclusion, wines must have scored 95 points or above from a leading critic, with the exception of the First Growths, Ausone, Cheval Blanc and Petrus, which are included on the basis of a score of 93 or above.  Any wine produced in the last fifteen vintages can be included in the index – prior to that only wines from strong vintages qualify, with the oldest being 1990.",
    type: "major",
    subIndexOf: null,
    bloombergName: null,
    thomsonReutersName: null,
    lastRebased: null
  },
  {
    name: "California 50",
    symbol: "CA50",
    description: "The California 50 index tracks the price performance of the ten most recent physical vintages of the five most actively traded Californian wines: Screaming Eagle, Opus One, Dominus, Harlan Estate and Ridge Monte Bello.",
    type: "regional",
    subIndexOf: null,
    bloombergName: null,
    thomsonReutersName: null,
    lastRebased: null
  },
  {
    name: "Port 50",
    symbol: "PORT50",
    description: "The Port 50 index tracks the price performance of the ten most recent physical vintages of the five most actively traded Port wines: Dow, Fonseca, Graham, Taylor and Warre.",
    type: "regional",
    subIndexOf: null,
    bloombergName: null,
    thomsonReutersName: null,
    lastRebased: null
  },
  {
    name: "Bordeaux Legends 40",
    symbol: "BDXL40",
    description: "The Bordeaux Legends 40 is a sub-index of the Liv-ex Fine Wine 1000. It tracks the price performance of a selection of 40 Bordeaux wines from exceptional older vintages (from 1989).",
    type: "regional",
    subIndexOf: "Liv-ex Fine Wine 1000",
    bloombergName: null,
    thomsonReutersName: null,
    lastRebased: null
  },
  {
    name: "Burgundy 150",
    symbol: "BGDY150",
    description: "The Burgundy 150 is a sub-index of the Liv-ex Fine Wine 1000. It tracks the price performance of the ten most recent physical vintages for 15 white and red Burgundies, including six Domaine Romanée Conti labels.",
    type: "regional",
    subIndexOf: "Liv-ex Fine Wine 1000",
    bloombergName: null,
    thomsonReutersName: null,
    lastRebased: null
  },
  {
    name: "Champagne 50",
    symbol: "CHAM50",
    description: "The Champagne 50 is a sub-index of the Liv-ex Fine Wine 1000. It tracks the price performance of the most recent physical vintages of the 11 most actively traded champagnes.",
    type: "regional",
    subIndexOf: "Liv-ex Fine Wine 1000",
    bloombergName: null,
    thomsonReutersName: null,
    lastRebased: null
  },
  {
    name: "Rhone 100",
    symbol: "RHO100",
    description: "The Rhone 100 is a sub-index of the Liv-ex Fine Wine 1000. It tracks the price performance of the ten most recent physical vintage for five southern and five northern Rhone wines.",
    type: "regional",
    subIndexOf: "Liv-ex Fine Wine 1000",
    bloombergName: null,
    thomsonReutersName: null,
    lastRebased: null
  },
  {
    name: "Italy 100",
    symbol: "ITAL100",
    description: "The Italy 100 is a sub-index of the Liv-ex Fine Wine 1000. It tracks the price performance of the ten most recent physical vintages for five ‘Super Tuscans’ and five other leading Italian producers.",
    type: "regional",
    subIndexOf: "Liv-ex Fine Wine 1000",
    bloombergName: null,
    thomsonReutersName: null,
    lastRebased: null
  },
  {
    name: "Rest of the World 60",
    symbol: "ROW60",
    description: "The Rest of the World 60 is a sub-index of the Liv-ex Fine Wine 1000. It tracks the price performance of the ten most recent physical vintages for six wines from Spain, Portugal, the U.S.A. and Australia: Vega Sicilia Unico, Taylor’s Port, Screaming Eagle, Opus One, Dominus and Penfolds Grange.",
    type: "regional",
    subIndexOf: "Liv-ex Fine Wine 1000",
    bloombergName: null,
    thomsonReutersName: null,
    lastRebased: null
  },
  {
    name: "Left Bank 200",
    symbol: "LB200",
    description: "The Left Bank 200 is a sub-index of the Liv-ex Bordeaux 500. It tracks the price performance of the ten most recent physical vintages for twenty wines from Bordeaux’s Left Bank.",
    type: "regional",
    subIndexOf: "Liv-ex Bordeaux 500",
    bloombergName: null,
    thomsonReutersName: null,
    lastRebased: null
  },
  {
    name: "Right Bank 50",
    symbol: "RB50",
    description: "The Right Bank 50 is a sub-index of the Liv-ex Bordeaux 500. It tracks the price performance of the ten most recent physical vintages for five leading wines from Bordeaux’s Right Bank: Ausone, Cheval Blanc, Lafleur, Petrus and Le Pin.",
    type: "regional",
    subIndexOf: "Liv-ex Bordeaux 500",
    bloombergName: null,
    thomsonReutersName: null,
    lastRebased: null
  },
  {
    name: "Second Wine 50",
    symbol: "SW50",
    description: "The Second Wines 50 is a sub-index of the Liv-ex Bordeaux 500. It tracks the price performance of the ten most recent physical vintages for five wines from the Bordeaux First Growths: Carruades Lafite, Clarence Haut Brion, Forts Latour, Pavillon Rouge and Petit Mouton.",
    type: "regional",
    subIndexOf: "Liv-ex Bordeaux 500",
    bloombergName: null,
    thomsonReutersName: null,
    lastRebased: null
  },
  {
    name: "Sauternes 50",
    symbol: "SA50",
    description: "The Sauternes 50 is a sub-index of the Liv-ex Bordeaux 500. It tracks the price performance of the ten most recent physical vintages for five wines from Sauternes: Climens, Coutet, Rieussec, Suduiraut and Yquem.",
    type: "regional",
    subIndexOf: "Liv-ex Bordeaux 500",
    bloombergName: null,
    thomsonReutersName: null,
    lastRebased: null
  },
  {
    name: "Right Bank 100",
    symbol: "RB100",
    description: "The Right Bank 100 is a sub-index of the Liv-ex Bordeaux 500. It tracks the price performance of the ten most recent physical vintages for ten wines from Bordeaux’s Right Bank.",
    type: "regional",
    subIndexOf: "Liv-ex Bordeaux 500",
    bloombergName: null,
    thomsonReutersName: null,
    lastRebased: null
  }
]

export const userTableHeadCells = [
  "ID",
  "First Name",
  "Last Name",
  "Email",
  "Role",
  "StripeID",
  "Created At",
];

export const rowsPerPageOptions = [25, 50, 100];

export const roleOptions = [
  "Enthusiast",
  "Retailer",
  "Restaurant",
  "Winemaker",
  "Distributor",
  "Importer",
  "Broker",
  "Auction House",
  "Media",
];
