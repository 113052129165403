import * as React from "react";
import { Snackbar } from "@material-ui/core";
import { Alert as MaterialAlert } from "@material-ui/lab";

type ContextProps = {
  children: React.ReactNode;
};

type AlertColor = "success" | "error" | "warning" | "info";

type AlertContextType = {
  openAlert: (
    message: string,
    severity?: AlertColor,
    autoHideDuration?: number
  ) => void;
};

const AlertContext = React.createContext({
  openAlert: () => undefined,
} as AlertContextType);

type AlertConfig = {
  uid: string;
  show: boolean;
  message: string;
  severity: AlertColor;
  autoHideDuration: number;
};

type Alert = {
  config: AlertConfig;
  margin: number;
};

type ReduceValue = {
  alertsResult: Alert[];
  prevMargin: number;
};
const defaultConfig: AlertConfig = {
  uid: "alert-uid-",
  show: true,
  message: "",
  severity: "success",
  autoHideDuration: 4000,
};

const AlertProvider: React.FC<ContextProps> = ({ children }) => {
  const uidRef = React.useRef(1);
  const [alertsQueue, setAlertsQueue] = React.useState<AlertConfig[]>([]);
  const handleToastClosed =
    (uid: string) => (_: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }
      setAlertsQueue((current) =>
        current.map<AlertConfig>((alert) => ({
          ...alert,
          show: uid === alert.uid ? false : alert.show,
        }))
      );
    };
  const openAlert = React.useCallback(
    (
      message: string,
      severity: AlertColor = defaultConfig.severity,
      autoHideDuration: number = defaultConfig.autoHideDuration
    ) => {
      uidRef.current = uidRef.current + 1;
      setAlertsQueue((current) => [
        ...current.filter(({ show }) => show),
        {
          uid: `${defaultConfig.uid}-${uidRef.current}`,
          show: true,
          message,
          severity,
          autoHideDuration,
        },
      ]);
    },
    []
  );

  const { alertsResult } = alertsQueue.reduce<ReduceValue>(
    (alertsInfo, config) => {
      return {
        alertsResult: [
          ...alertsInfo.alertsResult,
          { config, margin: alertsInfo.prevMargin },
        ],
        prevMargin: alertsInfo.prevMargin + (config.show ? 50 : 0),
      };
    },
    { alertsResult: [], prevMargin: 20 }
  );

  return (
    <AlertContext.Provider value={{ openAlert }}>
      {children}
      <>
        {alertsResult.reverse().map(({ config, margin }) => (
          <Snackbar
            key={config.uid}
            open={config.show}
            autoHideDuration={config.autoHideDuration}
            onClose={handleToastClosed(config.uid)}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <MaterialAlert
              onClose={handleToastClosed(config.uid)}
              severity={config.severity}
              style={{
                width: "100%",
                marginTop: `${margin}px`,
                zIndex: 1000,
              }}
            >
              {config.message}
            </MaterialAlert>
          </Snackbar>
        ))}
      </>
    </AlertContext.Provider>
  );
};

const useAlert = () => {
  return React.useContext(AlertContext);
};

export { AlertContext, AlertProvider, useAlert };
