import React, { useEffect } from "react";
import { LayoutWrapper } from "../../../components/layoutWrapper";
import useStyles from "../../../components/util/styles";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import { User } from "../../../context/authContext/reducer";
import { getAllUsers } from "../../../lib/adminRequests";
import { useHistory } from "react-router-dom";
import { rowsPerPageOptions, userTableHeadCells } from "../../../constants";
import { formatDate } from "../../../utils/admin";
import { useQuery } from "../../../hooks/useQuery";
import Colors from "../../../components/util/colors";
import { Alert } from "@material-ui/lab";

export const Users = () => {
  const classes = useStyles();
  const [users, setUsers] = React.useState([] as User[]);
  const history = useHistory();
  const { search, pathname } = useQuery();

  const pageNumber = Number(search.get("page"));
  const pageSize = Number(search.get("pageSize"));

  const initialPage = pageNumber > 0 ? pageNumber : 0;
  const initialRowsPerPage = rowsPerPageOptions.includes(pageSize)
    ? pageSize
    : 25;

  const [page, setPage] = React.useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = React.useState(initialRowsPerPage);
  const [initialError, setInitialError] = React.useState<string>("");

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  useEffect(() => {
    const loadAllUsers = async () => {
      try {
        const usersResponse = await getAllUsers();
        setUsers(usersResponse.users);
      } catch (err: any) {
        setInitialError(err);
      }
    };
    loadAllUsers();
  }, []);

  useEffect(() => {
    history.push(`${pathname}?page=${page}&pageSize=${rowsPerPage}`);
  }, [pathname, page, rowsPerPage, history]);

  return (
    <LayoutWrapper pathName="admin/users">
      <Container className={classes.container} maxWidth="xl">
        {initialError.length ? (
          <Alert severity="error">{initialError}</Alert>
        ) : (
          <div style={{ height: "auto", width: "100%" }}>
            <Table size="medium">
              <TableHead>
                <TableRow>
                  {userTableHeadCells.map((cell) => (
                    <TableCell
                      key={cell}
                      style={{
                        backgroundColor: Colors.vinousRed,
                        color: "white",
                      }}
                    >
                      <Typography style={{ fontWeight: "bold" }}>
                        {cell}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {users
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user) => (
                    <TableRow
                      key={user.id}
                      hover
                      onClick={() => history.push(`user/${user.id}`)}
                    >
                      <TableCell>{user.id}</TableCell>
                      <TableCell>{user.name}</TableCell>
                      <TableCell>{user.last_name}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.admin_role}</TableCell>
                      <TableCell>{user.customer_id}</TableCell>
                      <TableCell>{formatDate(user.created_at)}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>

            <TablePagination
              component="div"
              rowsPerPageOptions={rowsPerPageOptions}
              colSpan={3}
              count={users.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        )}
      </Container>
    </LayoutWrapper>
  );
};
