import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import {
  CircularProgress,
  CssBaseline,
  Divider,
  Drawer as MuiDrawer,
  ListItemText,
  ListItemIcon,
  ListItem,
  List,
  IconButton,
} from "@material-ui/core";
import {
  ChevronLeft,
  Settings,
  ListAlt as CellarsIcon,
  ExitToApp,
  AttachMoney as AttachMoneyIcon,
  ShowChartOutlined,
  LibraryBooksOutlined,
  SupervisorAccountOutlined as SupervisorAccount,
} from "@material-ui/icons";
import { Helmet } from "react-helmet";
import useStyles from "../util/styles";
import Copyright from "../copyright";
import Appbar from "../appbar";
import { logout, useAuth } from "../../context/authContext";
import { useCellarsContext } from "../../context/cellarsContext";
import { Cellar, Cellars } from "../../types/Cellar";
import StripeElementsWrapper from "../stripeElementsWrapper";
import { listCellars } from "../../context/cellarsContext/actions";
import delectableLogo from "../../images/delectable_logo.png";
import vinousLogo from "../../images/vinous_logo.png";
import { tokenIsAdmin } from "../../lib/localStorage";

type LayoutWrapperProps = {
  pathName: string;
  cellar?: Cellar;
  children?: ReactNode;
  loading?: boolean;
};
export const LayoutWrapper: React.FC<LayoutWrapperProps> = ({
  pathName,
  children,
  cellar,
  loading,
}: LayoutWrapperProps) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const getTitle = React.useCallback(() => {
    let title = null;
    if (cellar) {
      title = "Cellar";
    } else if (pathName) {
      title = pathName[0].toUpperCase() + pathName.slice(1);
      // Override
      if (title === "Cellars") { title = "Your Cellars" }
      if (title === "PriceHistory") { title = "Insight" }
    }
    return title
  }, [cellar, pathName]);

  if (loading)
    return (
      <div className={classes.spinnerContainer}>
        <CircularProgress />
      </div>
    );

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{`${getTitle() ? `${getTitle()} | ` : ""}Cellar Watch`}</title>
      </Helmet>
      <CssBaseline />
      <StripeElementsWrapper>
        <Appbar
          pathName={pathName}
          title={`${getTitle()  || "Cellar Watch"}`}
          cellar={cellar}
          open={open}
          handleDrawerOpen={() => {
            setOpen(true);
          }}
        />
      </StripeElementsWrapper>
      <Drawer open={open} setOpen={setOpen} pathName={pathName} />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {children}
        <Copyright />
      </main>
    </div>
  );
};

type DrawerProps = {
  open: boolean;
  setOpen: (v: boolean) => any;
  pathName: string;
};

export const Drawer: React.FC<DrawerProps> = ({
  open,
  setOpen,
  pathName,
}: DrawerProps) => {
  const classes = useStyles();
  const {
    state: { cellars, loading },
    dispatch,
  } = useCellarsContext();

  React.useEffect(() => {
    if (!cellars && !loading) {
      listCellars(dispatch);
    }
  }, [cellars, loading, dispatch]);

  return (
    <MuiDrawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={() => setOpen(false)}>
          <ChevronLeft />
        </IconButton>
      </div>
      <Divider />
      <PrimaryMenu
        cellars={cellars || {}}
        pathName={pathName}
      />
      <Divider />
        <List>
          <ListItem
            button
            component={Link}
            to="/marketReports"
            selected={pathName === "marketReports"}
          >
            <ListItemIcon>
              <ShowChartOutlined />
            </ListItemIcon>
            <ListItemText primary="Market Reports" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/glossary"
            selected={pathName === "glossary"}
          >
            <ListItemIcon>
              <LibraryBooksOutlined />
            </ListItemIcon>
            <ListItemText primary="Glossary" />
          </ListItem>
        </List>
      <Divider />
      <List>
        <ListItem
          button
          component="a"
          href="https://vinous.com"
          target="_blank"
        >
          <ListItemIcon>
            <img src={vinousLogo} alt="Vinous" height="20px"/>
          </ListItemIcon>
          <ListItemText primary="Vinous" />
        </ListItem>
        <ListItem
          button
          component="a"
          href="https://delectable.com"
          target="_blank"
        >
          <ListItemIcon>
            <img src={delectableLogo} alt="Delectable Logo" height="22px" />
          </ListItemIcon>
          <ListItemText primary="Delectable" />
        </ListItem>
      </List>
      <Divider />
      <SecondaryMenu pathName={pathName} />
    </MuiDrawer>
  );
};

type CellarsListProps = {
  pathName: string;
};
const CellarsList: React.FC<CellarsListProps> = ({
  pathName,
}: CellarsListProps) => {
  const classes = useStyles();

  return (
    <List>
      <ListItem
        button
        component={Link}
        to="/cellars"
        selected={pathName === "cellars"}
      >
        <ListItemIcon>
          <CellarsIcon
            className={
              pathName === "cellars" ? classes.drawerItemSelected : undefined
            }
          />
        </ListItemIcon>
        <ListItemText primary="My Cellars" />
      </ListItem>
      <ListItem
        button
        component={Link}
        to="/priceHistory"
        selected={pathName === "priceHistory"}
      >
        <ListItemIcon>
          <AttachMoneyIcon
            className={
              pathName === "priceHistory"
                ? classes.drawerItemSelected
                : undefined
            }
          />
        </ListItemIcon>
        <ListItemText primary="Price History" />
      </ListItem>
    </List>
  );
};

type PrimaryMenuProps = {
  cellars: Cellars;
  pathName: string;
};
const PrimaryMenu: React.FC<PrimaryMenuProps> = ({
  cellars,
  pathName,
}: PrimaryMenuProps) => {
  return cellars ? (
    <CellarsList
      pathName={pathName}
    />
  ) : null;
};

type SecondaryMenuProps = {
  pathName: string;
};
const SecondaryMenu: React.FC<SecondaryMenuProps> = ({
  pathName,
}: SecondaryMenuProps) => {
  const { dispatch } = useAuth();
  const { dispatch: cellarsDispatch } = useCellarsContext();
  const classes = useStyles();

  return (
    <List>
      <ListItem
        button
        component={Link}
        to="/settings"
        selected={pathName === "settings"}
      >
        <ListItemIcon>
          <Settings
            className={
              pathName === "settings" ? classes.drawerItemSelected : undefined
            }
          />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </ListItem>
      {tokenIsAdmin() && <ListItem
        button
        component={Link}
        to="/admin/users"
      >
        <ListItemIcon>
          <SupervisorAccount />
        </ListItemIcon>
        <ListItemText primary="Admin Dashboard" />
      </ListItem>}
      <ListItem
        button
        component={Link}
        onClick={() => logout(dispatch, cellarsDispatch)}
        to="/login"
      >
        <ListItemIcon>
          <ExitToApp />
        </ListItemIcon>
        <ListItemText primary="Log out" />
      </ListItem>
    </List>
  );
};
